.swiper {
  width: 100%;

  overflow-y: visible !important;
}

.techslider .swiper-slide {
  background-position: center;
  background-size: cover;
}

.techslider .swiper-slide img {
  display: block;
  width: 100%;
}

.techslider .swiper-pagination {
  bottom: -50px !important;
}

.techslider .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #03466a !important;
  width: 11px;
  height: 11px;
}

.techslider .swiper-pagination-bullet-active {
  background-color: #03466a !important;
}

@media (min-width: 992px) {
  .techslider .swiper-slide {
    width: 70vw;
    overflow: hidden !important;
  }
}
