.header.stikcy {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
}

.boxShadow {
  box-shadow: 6px 6px 18px rgba(3, 70, 106, 0.3);
}
