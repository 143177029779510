.LatesNewsSlider .swiper-pagination {
  bottom: -8%;
}

@media screen and (min-width: 768px) {
  .LatesNewsSlider .swiper-pagination {
    bottom: -30px;
  }
}

@media screen and (min-width: 992px) {
  .LatesNewsSlider .swiper-pagination {
    bottom: -8%;
  }
}

.LatesNewsSlider .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #7ac143 !important;
  width: 54px;
  height: 8px;
  border-radius: 11px;

  background-color: hsla(0, 0%, 100%, 0.2);
  border: 1px solid hsla(0, 0%, 100%, 0.4);
}

.LatesNewsSlider .swiper-pagination-bullet-active {
  background-color: #7ac143 !important;
}
