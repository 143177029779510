@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
}

.font-mono {
  font-family: "Montserrat", sans-serif;
}
.font-audio {
  font-family: "Audiowide", cursive;
}

.bg-grandient {
  background-image: linear-gradient(to top, #f3f4f6 0%, #ffffff 100%);
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.6rem;
}
@media screen and (min-width: 578px) {
  .footer-grid {
    grid-template-columns: 1fr 1fr;
    /* row-gap: 3.2rem; */
    /* column-gap: 1.6rem; */
  }
}
@media screen and (min-width: 992px) {
  .footer-grid {
    grid-template-columns: 1fr 1fr 2fr;
    row-gap: 0;
    column-gap: 3.2rem;
  }
}

.partners-bg {
  background-image: url(./assests/image/partners/hero/hero.jpg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.solution-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.customer-bg {
  background-image: url(./assests/image/customer/hero/customer-her.jpg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.news-hero {
  height: 100vh;
}
.bg-news-hero {
  background-image: url(./assests/image/media.jpg);
  background-repeat: no-repeat;
  /* background-position: right; */
  background-size: cover;
}

.transparent-bg {
  /* background: #03466a; */
  background: rgba(0, 0, 0, 0.412);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.258);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
