.successSwipper.swiper {
  display: flex;
  overflow-y: hidden !important;
  flex-direction: row-reverse;
  align-items: center;

  gap: 1.2rem;
}

.successSwipper .swiper-slide {
  width: 100% !important;
  display: flex !important;
  /* align-items: center !important; */
}

.success-dots-container {
  transform: translate3d(0px, 0, 0);
}

.successSwipper .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #03466a !important;
  width: 11px;
  height: 11px;
}
.successSwipper .swiper-pagination-bullet-active {
  background-color: #03466a !important;
}

/* @media screen and (min-width: 578px) {
  .successSwipper.swiper {
    max-height: 350px;
    gap: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .successSwipper.swiper {
    max-height: 450px;
  }
}
@media screen and (min-width: 1200px) {
  .successSwipper.swiper {
    max-height: 360px;
  }
} */
