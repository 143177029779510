.MobileBtnBox > span {
  display: block;
  background-color: #fff;
  width: 1.8rem;
  height: 2px;
  transition: all ease-in 0.3s;
}

.MobileBtnBox.isActive > span:nth-child(2) {
  display: none;
}

.MobileBtnBox.isActive > span {
  width: 1.6rem;
  height: 2px;
}

.MobileBtnBox span:nth-child(2) {
  max-width: 80%;
  margin-left: auto;
}
.MobileBtnBox.isActive > span:first-child {
  rotate: 135deg;
}

.MobileBtnBox.isActive > span:last-child {
  rotate: -135deg;
  position: absolute;
  /* margin-bottom: 10px; */
}
