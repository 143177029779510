.capabilties {
  width: 100%;
  height: 100%;
}

.capabilties .swiper-slide {
  min-height: 100% !important;
}

.capabilties .swiper-pagination {
  /* bottom: 3% !important; */
  position: relative;
  margin-top: 1.4rem;
}

.capabilties .swiper-pagination-bullet {
  background-color: white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.capabilties .swiper-pagination-bullet-active {
  background-color: #7ac143 !important;
}

.capab-prev-button,
.capab-next-button {
  background-color: #00000030;
  color: #7ac143;
  z-index: 100000;
  border-radius: 12px;
  height: 72px;
  transition: all ease-in 300ms;
  cursor: pointer;
}

.capab-prev-button::after {
  font-size: 26px;
}
.capab-next-button::after {
  font-size: 26px;
}

.capab-next-button:hover {
  background-color: #000000a1;
}
.capab-prev-button:hover {
  background-color: #000000a1;
}
