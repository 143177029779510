.mediaSlider .swiper-pagination {
  bottom: -10% !important;
}

.mediaSlider .image-slider {
  object-fit: contain !important;
}
.mediaSlider .swiper-pagination {
  bottom: -50px;
}
.mediaSlider .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #03466a !important;
  width: 11px;
  height: 11px;
}
.mediaSlider .swiper-pagination-bullet-active {
  background-color: #03466a !important;
}
