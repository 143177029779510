.bg-landing {
  background-size: cover;
  object-position: top;
  background-repeat: no-repeat;
  background-image: url(../../../assests/image/About/about-landing/small-blue.png);
}

.heroAboutSlider .swiper-pagination {
  bottom: 6% !important;
}

.heroAboutSlider .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #7ac143 !important;
  width: 54px;
  height: 8px;
  border-radius: 11px;

  background-color: hsla(0, 0%, 100%, 0.2);
  border: 1px solid hsla(0, 0%, 100%, 0.4);
}
.heroAboutSlider .swiper-pagination-bullet-active {
  background-color: #7ac143 !important;
}
.text span {
  color: white !important;
}
.about-us-slide-ul ul {
  color: white;
  list-style: disc;
  list-style-position: inside;
}

@media screen and (min-width: 992px) {
  .heroAboutSlider .swiper-pagination {
    bottom: 12% !important;
  }
}

.custom-scroll::-webkit-scrollbar {
  border-radius: 10px;
  width: 4px; /* Adjust the width of the scrollbar */
}

.custom-scroll::-webkit-scrollbar-track {
  background: #638da580; /* Background color of the scrollbar track */
  border-radius: 10px;
  height: 30%;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #03466a; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded edges for the thumb */
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}
