.partnerSwipper {
  position: relative;
}
.partnerSwipper .swiper-wrapper {
  display: flex !important;
  align-items: center !important;
  width: 100%;
}
.partnerSwipper.swiper-slide {
  width: 100%;
}

.partnerSwipper .image-slider {
  object-fit: contain !important;
}
.partnerSwipper .swiper-pagination {
  /* bottom: 3% !important; */
  position: relative;
  margin-top: 1.4rem;
}
.partnerSwipper .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 1px solid #03466a !important;
  width: 11px;
  height: 11px;
}
.partnerSwipper .swiper-pagination-bullet-active {
  background-color: #7ac143 !important;
  border: 1px solid transparent !important;
}
.swiper-button-prev:after .partnerSwipper.swiper-button-prev,
.partnerSwipper.swiper-button-next {
  background-color: #00477f;
  color: white;
  z-index: 100000;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  transition: all ease-in 300ms;
}
.swiper-button-prev:after {
  font-size: 1.6rem !important;
  background-color: #00477f;
  color: white;
  top: 30%;
  transform: translateY(-30%);
  background-color: #00000030;
  color: #7ac143;
  z-index: 100000;
  border-radius: 12px;
  height: 72px;
  transition: all ease-in 300ms;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-inline: 6px;
}

.swiper-button-next::after {
  font-size: 1.6rem !important;
  background-color: #00477f;
  color: white;
  top: 30%;
  transform: translateY(-30%);
  background-color: #00000030;
  color: #7ac143;
  z-index: 100000;
  border-radius: 12px;
  height: 72px;
  transition: all ease-in 300ms;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-inline: 6px;
}

.custom-prev-button,
.custom-next-button {
  position: absolute;
  top: 30%;
  transform: translateY(-30%);
  background-color: #00000030;
  color: #7ac143;
  z-index: 100000;
  border-radius: 12px;
  height: 72px;
  transition: all ease-in 300ms;
  cursor: pointer;
}

.custom-prev-button {
  font-size: 26px;
}
.custom-next-button {
  font-size: 26px;
}

.custom-next-button:hover {
  background-color: #000000a1;
}
.custom-prev-button:hover {
  background-color: #000000a1;
}

.custom-prev-button {
  left: -16%;
}

.custom-next-button {
  right: -16%;
}

@media (min-width: 768px) {
  .custom-prev-button {
    left: -10%;
  }

  .custom-next-button {
    right: -10%;
  }
}
@media (min-width: 992px) {
  .custom-prev-button {
    left: -6%;
  }

  .custom-next-button {
    right: -6%;
  }
}
