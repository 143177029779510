.heroSlider {
  width: 100%;
  height: 100%;
}

.heroSlider .swiper-slide {
  min-height: 100% !important;
}

.heroSlider .swiper-pagination {
  bottom: 3% !important;
}

.heroSlider .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #7ac143 !important;
  width: 54px;
  height: 8px;
  border-radius: 11px;

  background-color: hsla(0, 0%, 100%, 0.2);
  border: 1px solid hsla(0, 0%, 100%, 0.4);
}

.heroSlider .swiper-pagination-bullet-active {
  background-color: #7ac143 !important;
}

.zoom {
  transition: transform 0.5s ease;
}

.zoom.zoomed {
  animation: zoomInOut 3.8s alternate;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
.hero-section {
  height: 100dvh;
  width: 100%;
}

.slider-parent {
  height: 100dvh;
  width: 100%;
}

.content-parent {
  height: 100dvh;
  width: 100%;
}

.image-parent {
  height: 100dvh;
  width: 100%;
}

.hero-image {
  height: 100dvh;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 370px) {
  .hero-conent {
    width: 90%;
  }
}
@media screen and (min-width: 420px) {
  .hero-conent {
    width: 80%;
  }
}
@media screen and (min-width: 460px) {
  .hero-conent {
    width: 70%;
  }
}
@media screen and (min-width: 768px) {
  .hero-conent {
    width: 60%;
  }
}
/* hero */
@media screen and (min-width: 992px) {
  .hero-section {
    height: 100vh;
    width: 100%;
  }

  .slider-parent {
    height: 100vh;
    width: 100%;
  }

  .content-parent {
    height: 100vh;
    width: 100%;
  }

  .image-parent {
    height: 100vh;
    width: 100%;
  }

  .hero-image {
    height: 100vh;
    width: 100%;
  }
}

@media screen and (min-width: 1920px) {
  .hero-section {
    height: 80vh;
  }

  .slider-parent {
    height: 80vh;
  }

  .content-parent {
    height: 80vh;
  }

  .image-parent {
    height: 80vh;
  }

  .hero-image {
    height: 80vh;
  }

  .hero-conent {
    max-width: 50%;
    margin-inline: auto;
  }
}
@media screen and (min-width: 2560px) {
  .hero-section {
    height: 60vh;
  }

  .slider-parent {
    height: 60vh;
  }

  .content-parent {
    height: 60vh;
  }

  .image-parent {
    height: 60vh;
  }

  .hero-image {
    height: 60vh;
  }

  .hero-conent {
    max-width: 30%;
  }
}
