.hero-section {
  height: 100dvh;
  width: 100%;
}

.slider-parent {
  height: 100dvh;
  width: 100%;
}

.content-parent {
  height: 100dvh;
  width: 100%;
}

.image-parent {
  height: 100dvh;
  width: 100%;
}

.hero-image {
  height: 100dvh;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 370px) {
  .hero-conent {
    width: 90%;
  }
}
@media screen and (min-width: 420px) {
  .hero-conent {
    width: 80%;
  }
}
@media screen and (min-width: 460px) {
  .hero-conent {
    width: 70%;
  }
}
@media screen and (min-width: 768px) {
  .hero-conent {
    width: 60%;
  }
}
/* hero */
@media screen and (min-width: 992px) {
  .hero-section {
    height: 100vh;
    width: 100%;
  }

  .slider-parent {
    height: 100vh;
    width: 100%;
  }

  .content-parent {
    height: 100vh;
    width: 100%;
  }

  .image-parent {
    height: 100vh;
    width: 100%;
  }

  .hero-image {
    height: 100vh;
    width: 100%;
  }
}

@media screen and (min-width: 1920px) {
  .hero-section {
    height: 80vh;
  }

  .slider-parent {
    height: 80vh;
  }

  .content-parent {
    height: 80vh;
  }

  .image-parent {
    height: 80vh;
  }

  .hero-image {
    height: 80vh;
  }

  .hero-conent {
    max-width: 50%;
    margin-inline: auto;
  }
}
@media screen and (min-width: 2560px) {
  .hero-section {
    height: 60vh;
  }

  .slider-parent {
    height: 60vh;
  }

  .content-parent {
    height: 60vh;
  }

  .image-parent {
    height: 60vh;
  }

  .hero-image {
    height: 60vh;
  }

  .hero-conent {
    max-width: 30%;
  }
}
