.otherSucessSlider .swiper-wrapper {
  display: flex !important;
  align-items: center !important;
  width: 100%;
}
.otherSucessSlider.swiper-slide {
  width: 100%;
}

.otherSucessSlider .image-slider {
  object-fit: contain !important;
}
.otherSucessSlider .swiper-pagination {
  bottom: -50px;
}
.otherSucessSlider .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #7ac143 !important;
  width: 11px;
  height: 11px;
}
.otherSucessSlider .swiper-pagination-bullet-active {
  background-color: #7ac143 !important;
}
