.news-hero {
  height: auto;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .news-hero {
    height: 100vh;
    width: 100%;
  }

  .news-content-parent {
    height: 100vh;
    width: 100%;
  }
}

@media screen and (min-width: 1920px) {
  .news-hero {
    height: 90vh;
  }
  .news-content-parent {
    height: 90vh;
  }
}
@media screen and (min-width: 2560px) {
  .news-hero {
    height: 80vh;
  }
  .news-content-parent {
    height: 80vh;
  }
}
