.solution-prev-button,
.solution-next-button {
  background-color: #00000030;
  color: #7ac143;
  z-index: 100000;
  border-radius: 12px;
  height: 72px;
  transition: all ease-in 300ms;
  cursor: pointer;
}

.solution-prev-button {
  font-size: 26px;
}
.solution-next-button {
  font-size: 26px;
}

.solution-next-button:hover {
  background-color: #000000a1;
}
.solution-prev-button:hover {
  background-color: #000000a1;
}
