.successGallerySlider .swiper-wrapper {
  display: flex !important;
  align-items: center !important;

  width: 100%;
}
.successGallerySlider .swiper-slide {
  /* width: 100%; */
  /* flex: 1; */
}

.successGallerySlider .image-slider {
  object-fit: contain !important;
}
.successGallerySlider .swiper-pagination {
  bottom: -30px;
}
.successGallerySlider .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #7ac143 !important;
  width: 11px;
  height: 11px;
}
.successGallerySlider .swiper-pagination-bullet-active {
  background-color: #7ac143 !important;
}
